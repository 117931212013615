<template>
  <v-card-text class="mt-12 border-s-lg pb-3">
    <h3 class="text-center text-katal-title pb-3">Cambio de clave temporal</h3>
    <div class="text-center text-katal-title3 pb-3">
      Debe tener la clave temporal que fue enviada a su correo <br />Con esta
      clave temporal, usted podrá crear su propia clave. <br /><br />
      <span class="text-caption">
        Esta nueva clave debe debe tener al menos 10 carácteres, a lo menos una
        mayúscula, una minuscula y un número.</span
      >
    </div>
    <v-form
      ref="formChange"
      v-model="formChange.validForm"
      @submit.prevent="submitformChange"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" class="mt-5 border-col">
          <clave
            :autofocusSet="autof1"
            labelSet="Clave asignada"
            MensajeAyudaSet="Ingrese la clave que fue asignada por correo"
            :requeridoSet="true"
            :validatingSet="formChange.validating"
            :valorSet="passwordOld"
            ref="formChangePassword"
            class="mt-3"
          ></clave> </v-col
        >
        <v-col cols="12" sm="8" class="border-col">
          <clave
            :autofocusSet="autof2"
            labelSet="Clave nueva"
            MensajeAyudaSet="Ingrese su nueva clave."
            :requeridoSet="true"
            :validatingSet="formChange.validating"
            :valorSet="passwordNew"
            ref="formChangepasswordNew"
            class="mt-3"
          ></clave> </v-col
        >
        <v-col cols="12" sm="8" class="border-col">
          <clave
            :autofocusSet="false"
            labelSet="Repetir clave nueva"
            MensajeAyudaSet="Ingrese su nueva clave."
            :requeridoSet="true"
            :validatingSet="formChange.validating"
            :valorSet="passwordReNew"
            ref="formChangepasswordReNew"
            class="mt-3"
          ></clave> </v-col
        >
        <v-col cols="12" sm="8" class="border-col">
          <botonSend labelSet="Cambiar Clave"></botonSend>
        </v-col>
        <v-col cols="12" sm="8" class="border-col">
          <formbtncancel
            labelSet="Volver"
            @accion="cancel"
            :validating="formChange.validating"
          >
          </formbtncancel>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";
import { rulesClave } from "@/helpers/funcionesEstandard";
import clave from "@/components/form/password.vue";
import botonSend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";


export default {
  data: () => ({
    formChange: {
      validForm: null,
      validating: null,
      email: null,
      passwordOld: null,
      passwordNew: null,
      passwordReNew: null,
    },
    rulesClave: rulesClave,
  }),
  components: {
    clave,
    botonSend,
    formbtncancel,
  },
  mounted: function () {
    this.revisarPaso("mounted");
  },
  watch: {
    step: function (val) {
      this.revisarPaso("watch");
    },
  },
  props: {
    step: Number,
    email: String,
    passwordOld: String,
  },
  methods: {
    cancel: function () {
      this.$emit("cambiarPaso","1");
    },
    revisarPaso: async function (evento) {
      if (this.step == 5) {
        this.formChange = {
          validForm: null,
          validating: null,
          email: null,
          passwordOld: null,
          passwordNew: null,
          passwordReNew: null,
        };
        if (this.passwordOld) {
          this.autof1 = false;
          this.autof2 = true;
        }
        if (this.email) {
          this.formChange.email = this.email;
          this.autof2 = true;
          this.autof2 = false;
        }
      }
    },
    submitformChange: async function () {
      this.formChange.validating = true;
      const { valid } = await this.$refs.formChange.validate();
      if (valid) {
        if (
          this.$refs.formChangepasswordNew.valor ==
          this.$refs.formChangepasswordReNew.valor
        ) {
          let fd = new FormData();
          fd.append("email", this.formChange.email);
          fd.append("passwordOld", this.$refs.formChangePassword.valor);
          fd.append("passwordNew", this.$refs.formChangepasswordNew.valor);
          var json = await enviarJsonPOST("usuario/cambiar_clave", fd);

          if (json.error == 0) {
            await this.$emit("cambiarPaso", 1);
          }
        } else {
          json = {
            error: 1,
            message: "La clave y su confirmación no son iguales.",
          };
        }
        await this.$emit("validarMensaje", json);
      }
      this.formChange.validating = false;
    },

    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
  },
};
</script>