<template>
  <v-row>
    <v-col cols="12" sm="8" lg="9" align="center" justify="center">
      <v-row class="mt-10 ml-5 mr-5 mb-5">
        <v-col cols="12">
          Bienvenido
          <span class="text-blue-darken-4 font-weight-black">{{
            user.name
          }}</span
          >, estos son las solicitudes vigentes que tienes:
        </v-col>
        <v-col cols="12" md="6" v-for="credito in creditos" :key="credito">
          <solicitudCredito :credito="credito"></solicitudCredito>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      sm="4"
      lg="3"
      class="bg-blue-lighten-2 rounded-bs-circle"
      align="center"
    >
      <br />
      <opcionesMenuRapido :user="user"></opcionesMenuRapido>
    </v-col>
  </v-row>
</template>

<script>
import { obtenerJsonGET } from "@/helpers/funcionesEstandard";
import botonRapido from "@/components/Usuario/principal/botonRapido.vue";
import solicitudCredito from "@/components/Usuario/principal/creditoSolicitado.vue";
import opcionesMenuRapido from "@/components/Usuario/principal/opcionesMenuRapido.vue";

export default {
  data: () => ({
    creditos: null,
  }),
  components: {
    botonRapido,
    solicitudCredito,
    opcionesMenuRapido,
  },
  props: {
    user: Object,
  },
  mounted: async function () {
    if (!this.user) {
      this.$router.push("/");
    } else {
      if (!this.user.token) {
        this.$router.push("/");
      } else {
        var SendData = {
          usuarioId: this.user.usuarioId,
        };
        var resp = await obtenerJsonGET(
          "principal/obtener_solicitudes",
          SendData
        );
        if (resp.error == 0) {
          this.creditos = resp.data;
        } else {
          this.validarMensaje(resp);
        }
      }
    }
  },
  methods: {
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    opcionRapida: async function (paso) {
      alert("crea uno nuevo");
    },
  },
};
</script>