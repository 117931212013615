import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/Usuario/seguridad/login.vue';
import nuevoRegistro from "@/components/Usuario/seguridad/NuevoRegistro.vue";
import ValidaCorreo from "@/components/Usuario/seguridad/ValidaCorreo.vue";


import menuPrincipal from '@/components/Usuario/principal/menuPrincipal.vue';


const routes = [
  {
    path: '/',
    name: 'home',
    component: Login
  },
  {
    path: '/nuevoRegistro',
    name: 'nuevoRegistro',
    component: nuevoRegistro
  },
  {
    path: '/ValidaCorreo',
    name: 'ValidaCorreo',
    component: ValidaCorreo
  },
  {
    path: '/ValidaCorreo/:email',
    name: 'ValidaCorreoE',
    component: ValidaCorreo
  },
  {
    path: '/ValidaCorreo/:email/:codigo',
    name: 'ValidaCorreoEC',
    component: ValidaCorreo
  },
  {
    path: '/CambioClave/:email/:password',
    name: 'cambioclave2',
    component: Login
  },
  {
    path: '/CambioClave/:email/',
    name: 'cambioclave1',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/validarcodigo/:email/:codigo',
    name: 'validarcodigo',
    component: Login
  },
  {
    path: '/ingresarusuariovalidado/:email/:clave',
    name: 'ingresarusuariovalidado',
    component: Login
  },
  {
    path: '/menuPrincipal',
    name: 'menuPrincipal',
    component: menuPrincipal
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
