<template>
    <div v-show="step==1">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          class="gradient-background rounded-be-circle text-wite"
        >
        <v-img src="/img/sitio_en_construccion.png"></v-img>
          <!--IngresoInicialSistema
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></IngresoInicialSistema-->
        </v-col>
        <v-col cols="12" sm="6">
            
        <v-img src="/img/sitio_en_construccion.png"></v-img>
          <!--ingresoUsuarioRegistrado
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            @registraCorreo="registraCorreo"
            @setUsuario="setUsuario"
            :step="step"
            :email="email"
          ></ingresoUsuarioRegistrado-->
        </v-col>
      </v-row>
    </div>
    <div v-show="step==3">
      <v-row>
        <v-col cols="12" sm="6">
          <validaCorreoNuevo
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
            :email="email"
            :codigo="codigo"
          ></validaCorreoNuevo>
        </v-col>
        <v-col cols="12" sm="6" class="bg-blue-lighten-2 rounded-bs-circle">
          <seccionIngresoUsuario
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></seccionIngresoUsuario>
        </v-col>
      </v-row>
    </div>

    <div v-show="step==4">
      <div class="pa-4 text-center">
        <v-img class="mb-4" height="128" src="/img/credigo.png" contain></v-img>
        <div class="text-h6 text-sm-h3 font-weight-light mb-2">
          CrediGO agradece su preferencia!
        </div>
        <div class="text-caption text-sm-h4 text-grey bm-5">
          Ha sido enviado un correo electronico a {{ email }} con instrucciones
          para continuar con el proceso.
        </div>
        <v-btn @click="cambiarPaso(1)" variant="outlined" class="bg-blue"
          >Ingresar al sistema</v-btn
        >
      </div>
    </div>

    <div v-show="step==5">
      <v-row>
        <v-col cols="12" sm="6" class="gradient-background rounded-be-circle text-wite">
          <IngresoInicialSistema
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></IngresoInicialSistema>
        </v-col>
        <v-col cols="12" sm="6">
          <cambioClaveTemporal
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
            :email="email"
            :passwordOld="passwordOld"
          ></cambioClaveTemporal>
        </v-col>
      </v-row>
    </div>
    <div v-show="step==6">
      <v-row>
        <v-col cols="12" sm="6" class="gradient-background rounded-be-circle text-wite">
          <IngresoInicialSistema
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
          ></IngresoInicialSistema>
          </v-col>  
        <v-col cols="12" sm="6">
          <recuperarClave
            @cambiarPaso="cambiarPaso"
            @validarMensaje="validarMensaje"
            :step="step"
          ></recuperarClave>
        </v-col>
      </v-row>
      
    </div>
    <v-row>
        
        <v-col cols="12" class="pa-6">
          <v-card class="bg-blue-lighten-5 ma-6">
            <v-card-title>¡Bienvenidos al sistema de <span class="d-sm-none"><br></span>gestión de flotas KATAL! </v-card-title>
            <v-card-text class="elevation-6">
              <v-row>
                <v-col cols="12 mt-6 text-caption" >
                  <p>
                    En el competitivo mundo del transporte privado de pasajeros, la eficiencia y la gestión inteligente son clave para el éxito. 
                    Imagina tener el control total de tus carreras, pagos, gastos, y más, todo desde una plataforma informática integrada diseñada 
                    para simplificar tu operación y maximizar tus ganancias.
                  </p>
                </v-col>
                <v-col cols="12">
                  <p class="text-katal-subtitle">
                    ¿Por qué deberías considerar nuestro sistema informático para la gestión de carreras?
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black ml-4">
                    <ul><li>
                    Eficiencia Operativa
                  </li></ul>
                  </p>
                  <p class="text-caption">
                    Asigna automáticamente carreras a conductores disponibles, optimizando la utilización de tu flota.
                    Minimiza tiempos de espera y mejora la satisfacción del cliente al ofrecer un servicio más rápido y eficiente.
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black ml-4">
                    <ul><li>
                    Control Total de Gastos
                    </li></ul>
                  </p>
                  <p class="text-caption">
                    Registra y categoriza fácilmente todos los gastos asociados con tu flota, desde combustible hasta mantenimiento.
                    Identifica áreas de oportunidad para reducir costos y maximizar la rentabilidad de cada carrera.
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black ml-4">
                    <ul><li>
                    Gestión de Pagos Simplificada
                    </li></ul>
                  </p>
                  <p class="text-caption">
                    Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual.
                    Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones.
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black ml-4">
                    <ul><li>
                    Control de TAG
                    </li></ul>
                  </p>
                  <p class="text-caption">
                    Procesa pagos de manera rápida y segura a través de nuestra plataforma, eliminando la complejidad de la facturación manual.
                    Genera automáticamente recibos electrónicos para una contabilidad sin complicaciones.
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black ml-4">
                    Beneficios Adicionales:
                  </p>
                  <p class="text-caption">
                    Mayor transparencia en los costos para conductores y operadores.
                    Análisis detallado del rendimiento operativo para una toma de decisiones informada.
                    Experiencia mejorada para los usuarios finales, con tiempos de espera reducidos y un servicio más confiable.
                  </p>
                </v-col>
                
                <v-col cols="12">
                  <p class="text-caption">
                    No te quedes atrás en la revolución digital del transporte privado. Nuestra plataforma informática está diseñada para llevar tu negocio al siguiente nivel, ofreciendo eficiencia, control financiero y una ventaja competitiva. ¡Haz el cambio hoy y experimenta la transformación en la gestión de carreras de transporte privado!
                  </p>
                </v-col>
                <v-col cols="12">
                  <p class="text-h6">
                    Modulos Principales
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black">
                    a. Gestión de Carreras:
                  </p>
                  <p class="text-caption ml-4">
                    <ul>
                      <li>Información detallada sobre horarios, servicio, convenio, tarifas de las carreras por cada conductor.</li>
                      <li>Respaldo en imagen de los vales que se haya emitido en la carrera.</li>
                      <li>Observaciones que puedan particularizar cualquier información relevante con la carrera en cuestion.</li>
                    </ul>
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black">
                    b. Control de TAG:
                  </p>
                  <p class="text-caption ml-4">
                    <ul>
                      <li>Información detallada de los TAG que se utilizan durante el uso de vehículos.</li>
                      <li>Tener actualizado y en línea los gastos de TAG de su empresa.</li>
                      <li>Cargar los TAG al sistema utilizando directamene los archivos Excel o CSV que entrega cada autopista, permitiendo así, tener un detalle exausito de estos, con fecha, portico y monto del mismo.</li>
                      <li>Inteligencia para determinar si un peaje corresponde está o no dentro de una carrera.</li>
                      <li>Inteligencia para determinar si un peaje corresponde está o no dentro del turno de un conductor.</li>
                    </ul>
                  </p>
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="font-weight-black">
                    c. Registro de Gastos:
                  </p>
                  <p class="text-caption ml-4">
                    <ul>
                      <li>Controlar los dineros que se entregan para uso del vehículo.</li>
                      <li>Controlar los gastos que se realizan en cada vehículo.</li>
                      <li>Tener una bitacora con los diversos arreglos con sus correspondientes fechas.</li>
                      <li>Permite diferenciar las responsabildades de los gastos.</li>
                    </ul>
                  </p>
                </v-col>
                <v-col cols="12" class="mt-6">
                  Para ponerse en contacto escribanos a <a href="mailto: contacto@transporte.katal.cl">contacto@transporte.katal.cl</a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>
var clave = null;
import {
  rulesRut,
  rulesMail,
  rulesName,
  rulesApellido,
  rulesEmpresa,
  rulesClave,
} from "@/helpers/funcionesEstandard";

import seccionIngresoUsuario from "@/components/Usuario/seguridad/seccionIngresoUsuario.vue";
import IngresoInicialSistema from "@/components/Usuario/seguridad/IngresoInicialSistema.vue";
import ingresoUsuarioRegistrado from "@/components/Usuario/seguridad/ingresoUsuarioRegistrado.vue";
import validaCorreoNuevo from "@/components/Usuario/seguridad/validaCorreoNuevo.vue";
import cambioClaveTemporal from "@/components/Usuario/seguridad/cambioClaveTemporal.vue";
import recuperarClave from "@/components/Usuario/seguridad/recuperarClave.vue";

export default {
  data: () => ({
    email: null,
    codigo: null,
    passwordOld: null,
    msg: {
      number: null,
      code: null,
      title: null,
      text: null,
    },
    dialogEvalMessage: false,
    step: 1,
    rulesRut: rulesRut,
    rulesMail: rulesMail,
    rulesName: rulesName,
    rulesApellido: rulesApellido,
    rulesEmpresa: rulesEmpresa,
    rulesClave: rulesClave,
  }),
  props: {
    user: Object,
  },
  components: {
    seccionIngresoUsuario,
    IngresoInicialSistema,
    ingresoUsuarioRegistrado,
    validaCorreoNuevo,
    cambioClaveTemporal,
    recuperarClave,
  },
  watch: {
    tipoRegistroNuevo: function (val) {
      requiereEmpresa = val == "1" ? true : false;
    },
  },
  mounted: function () {
    if (this.$route.params.email) {
      this.email = this.$route.params.email;
      this.passwordOld = null;
      sessionStorage.setItem("correoRecuperaClave",this.$route.params.email);
      if (this.$route.params.password) {
        sessionStorage.setItem("passwordRecuperaClave",this.$route.params.password);
        this.passwordOld = this.$route.params.password;
      }
      this.$router.push("/");
      this.cambiarPaso(5);
    } else if (sessionStorage.getItem("correoRecuperaClave")) {
      this.email = sessionStorage.getItem("correoRecuperaClave");
      sessionStorage.removeItem("correoRecuperaClave");
      if (sessionStorage.getItem("passwordRecuperaClave")) {
        this.opt = sessionStorage.getItem("passwordRecuperaClave");
        sessionStorage.removeItem("passwordRecuperaClave");
      }
      this.cambiarPaso(5);
    }
  },
  methods: {
    cambiarPaso: async function (paso) {
      this.step = paso;
      switch (this.step) {
        case 1:
          this.$router.push("/");
          break;
        case 10:
          this.$router.push("/menuPrincipal");
          break;
      }
    },
    registraCorreo: async function (email) {
      this.email = email;
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setUsuario: async function (usuario) {
      this.$emit("setUsuario", usuario);
    },
  },
};
</script>

<style>
.v-appication .rounded-bs-xl {
  border-bottom-left-radius: 300px !important;
}

.v-appication .rounded-bl-xl {
  border-bottom-right-radius: 300px !important;
}
</style>