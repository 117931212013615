<template>
    <v-badge  color="blue" :content="credito.ofertas">
  <v-card border="opacity-50 sm" class="mx-auto" rounded="xl" variant="text">
    <v-row class="ma-1">
      <v-col cols="12" class="border-col">
        <div class="text-h6 text-sm-h5 font-weight-bold border-col">
          {{ credito.TipoCredito }}
        </div>
        <div
          class="text-caption text-sm-h6 text-blue-accent-1 font-weight-bold border-col"
        >
          {{ credito.nombre }}
          <v-tooltip activator="parent" location="bottom">{{
            credito.descripcion
          }}</v-tooltip>
        </div>
      </v-col>
      <v-col cols="12" class="border-col">
        <div class="text-h6 text-sm-h4 text-blue-darken-4 font-weight-black border-col">
          {{ formatoMonedaChile(credito.monto_solicitado, 0) }}
        </div>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        class="text-caption text-medium-emphasis align-center border-col d-flex justify-space-between"
      >
        {{ credito.numero_cuotas }} cuota(s) primer pago {{ credito.fecha_primera_cuota }}
      </v-col>
      <v-col
        cols="12"
        lg="6" class="border-col"
        ><small
          class="text-caption text-medium-emphasis d-flex justify-space-between align-center border-col"
        >
          <div class="text-green border-col">
            Tiene {{ credito.ofertas }} ofertas hasta ahora
          </div>
          <span>
          <v-btn
            icon="mdi-arrow-right"
            size="x-small"
            @click="verOfertas(credito.id)"
            v-if="credito.ofertas > 0"
            border
          >
          
        </v-btn>
        <v-tooltip activator="parent" location="bottom">
          Presione este botón para ver las ofertas que tiene  
          </v-tooltip>
        </span>
        </small>
      </v-col>
      <v-col> </v-col>
    </v-row>
  </v-card>
  </v-badge>
</template>

<script>
import { formatoMonedaChile } from "@/helpers/funcionesEstandard";

export default {
  data: () => ({}),
  props: {
    credito: Object,
  },
  methods: {
    formatoMonedaChile: function (number) {
      return formatoMonedaChile(number, 0);
    },
    verOfertas: function(id){
          this.$router.push('/listaOfertasCredito/'+id);
    }
  },
};
</script>