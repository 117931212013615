<template>
  <v-card-text class="mt-12 border-s-lg">
    <div class="text-center text-katal-title">Recuperación de clave</div>
    <div class="text-center text-katal-title3 mt-3">
      Debe ingresar el correo electronico registrado en el sistema
      <br /><br />
      <span class="text-caption"
        >le llegará un correo electrónico con una clave temporal que le
        permitirá cambiar su clave. <br /><br />Con esto, podrá ingresar al
        sistema y continuar usando sus beneficios.</span
      >
    </div>
    <v-form
      ref="formRecuperar"
      v-model="formRecuperar.validForm"
      @submit.prevent="submitformRecuperar"
    >
      <v-row align="center" justify="center" class="mt-6">
        <v-col cols="12" sm="8">
          <v-text-field
            label="Email"
            variant="outlined"
            autofocus
            ref="formRecuperarEmail"
            v-model="formRecuperar.email"
            density="compact"
            autocomplete="false"
            prepend-inner-icon="mdi-email-outline"
            :loading="formRecuperar.validating"
            :disabled="formRecuperar.validating"
            :rules="[rulesMail.requerido, rulesMail.minChar, rulesMail.valida]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="8">
          <formbtnsend
            labelSet="Recuperar clave"
            :validating="formRecuperar.validating"
          ></formbtnsend>
        </v-col>
        <v-col cols="12" sm="8">
          <formbtncancel
            labelSet="Volver"
            @accion="cancel"
            :validating="formRecuperar.validating"
          >
          </formbtncancel>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { enviarJsonPOST } from "@/helpers/funcionesEstandard";
import { rulesMail } from "@/helpers/funcionesEstandard";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";

export default {
  data: () => ({
    formRecuperar: {
      validForm: null,
      loading: null,
      validating: null,
      email: "",
    },
    rulesMail: rulesMail,
  }),
  components: {
    formbtnsend,
    formbtncancel,
  },
  watch: {
    step: function (val) {
      this.revisarPaso("watch");
    },
  },
  mounted: function () {
    this.revisarPaso("mounted");
  },
  props: {
    step: Number,
  },
  methods: {
    cancel: function () {
      this.$emit("cambiarPaso","1");
    },
    submitformRecuperar: async function () {
      this.formRecuperar.validating = true;
      const { valid } = await this.$refs.formRecuperar.validate();
      if (valid) {
        let fd = new FormData();
        fd.append("email", this.formRecuperar.email);
        var json = await enviarJsonPOST("usuario/recuperar_clave", fd);

        if (json.error == 0) {
          await this.$emit("cambiarPaso", 1);
        }
        await this.$emit("validarMensaje", json);
      }
      this.formRecuperar.validating = false;
    },
    revisarPaso: async function (evento) {
      if (this.step == 6) {
        this.formRecuperar = {
          validForm: null,
          validating: null,
          email: null,
        };
        if (this.$refs.formRecuperar) {
          this.$refs.formRecuperar.reset();
        }
        if (this.$refs.formRecuperarEmail) {
          this.$refs.formRecuperarEmail.focus();
        }
      }
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
  },
};
</script>
