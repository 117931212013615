<template>
  <div
    style="text-align: center"
    class="pt-5 pt-sm-10 pb-0 pb-sm-10 pl-6 pl-sm-10"
  >
    <v-row class="mr-6 mr-sm-16 mb-sm-16 mb-8" align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-card-text class="text-white">
          <div align="center" justify="center" class="mt-1 mb-5 mb-sm-10">
            <v-img
              src="/img/LogoSolo.png"
              width="50%"
              class="rounded-xl"
            ></v-img>
          </div>
          <div class="text-center text-katal-title-beautiful">¿Aún no tienes cuenta?</div>
          <span class="text-center text-katal-subtitle-beautiful">Regístrate con nosotros</span>
        </v-card-text>
      </v-col>
      <v-col cols="12" align="center" justify="center">
        <botonCancel labelSet="Registrate" @accion="nuevoRegistro"></botonCancel>
      </v-col>
      <v-col cols="12">
        <div class="text-center text-katal-title-beautiful3">Al registrarte podrás controlar tus ingresos, gastos y pagos detalladamente.</div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import validaCorreoNuevo from "./validaCorreoNuevo.vue";
import botonCancel from "@/components/form/buttonCancelform.vue";

export default {
  components: { validaCorreoNuevo,botonCancel },
  methods: {
    cambiarPaso: async function (paso) {
      this.$emit("cambiarPaso", paso);
    },
    nuevoRegistro: function(){
      this.$router.push("/nuevoRegistro");
    }
  },
};
</script>