<template>
  <v-radio-group :inline="inline" v-model="valor">
    <v-radio
      v-for="opcion in opciones"
      :key="opcion"
      :label="opcion.label"
      :value="opcion.valor + ''"
    ></v-radio>
  </v-radio-group>
</template>

<script>
export default {
  data: () => ({
    valor: null,
    inline: false,
  }),
  props: {
    opciones: Array,
    valorSet: String,
    inlineSet: Boolean,
  },
  mounted: function () {
    if (this.valorSet) {
      this.valor = this.valorSet;
    }
    if(this.inlineSet){
        this.inline = this.inlineSet;
    }
  },
  watch: {
    valor: function (val) {
      this.$emit("SetValor", val);
    },
    valorSet: function (val) {
      this.valor = val + "";
    },
    inlineSet: function(val){
        this.inline = val;
    }
  },
};
</script>

<style>
</style>