<template>
  <div style="text-align: center" class="pt-5 pt-sm-16 pb-10 mr-10">
    <v-card-text class="white--text">
      <h3 class="text-center">¿Ya tiene usuario para ingresar?</h3>
      <div align="center" justify="center" class="mt-10 mb-10">
        <v-img src="/img/credigo.png" width="30%" class="bg-white rounded-xl"></v-img>
      </div>
      <span class="text-center"
        >Puedes iniciar sesión para seguir disfrutando de todas las ventajas de CrediGo.</span
      >
    </v-card-text>
    <div class="text-center">
      <v-btn @click="cambiarPaso(1)" variant="outlined" class="bg-blue"
        >Ingresar usuario registrado</v-btn
      >
    </div>
  </div>
</template>
<script>
export default {
    methods: {
        cambiarPaso: async function(paso){
            this.$emit("cambiarPaso",paso);
        }
    }
}
</script>
