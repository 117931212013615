<template>
  <v-card-text class="mt-12 ml-2 mr-2 border-s-lg">
    <v-form ref="formNew" v-model="validForm" @submit.prevent="submitNew">
      <v-row justify="center">
        <v-col cols="6" sm="2" class="align-self-center">
          <v-img
            src="/img/LogoSolo.png"
            width="100%"
            class="rounded-xl"
          ></v-img>
        </v-col>
        <v-col cols="12" sm="10">
          <v-row>
            <v-col cols="12" class="text-center text-katal-title"
              >Validación de correo</v-col
            >

            <v-col cols="12" class="text-center text-katal-title3">
              Ingrese el cóidigo que llego a tu correo {{ correo }}</v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-8">
          <v-row class="border border-opacity-25 rounded elevation-25">
            <v-col cols="12" class="border-col">
              <v-otp-input
                v-model="opt"
                ref="formOPTopt"
                autofocus
                color="primary"
                focus-all
                :loading="validating"
                :disabled="validating"
                variant="outlined"
              ></v-otp-input>
            </v-col>
            <v-col cols="6">
              <formbtnsend
                labelSet="Registrar"
                :validating="validating"
              ></formbtnsend>
            </v-col>
            <v-col cols="6">
              <formbtncancel
                labelSet="Volver"
                @accion="volver"
                :validating="validating"
              ></formbtncancel>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
import { obtenerJsonGET, enviarJsonPOST } from "@/helpers/funcionesEstandard";

import formemail from "@/components/form/email.vue";
import formrut from "@/components/form/rut.vue";
import formtext from "@/components/form/textos.vue";
import formbtnsend from "@/components/form/buttonSubmitform.vue";
import formbtncancel from "@/components/form/buttonCancelform.vue";
import checkTipoUsuario from "@/components/form/checkBox.vue";
import formnumero from "@/components/form/numero.vue";
import formmultiplecombobox from "@/components/form/multiplecombobox.vue";

export default {
  data: () => ({
    opt: null,
    correo: null,

    validating: false,
  }),
  mounted: async function () {
    if (this.$route.params.email) {
      this.correo = this.$route.params.email;
      if (this.$route.params.codigo) {
        this.opt = this.$route.params.codigo;
      }
      this.$router.push("/ValidaCorreo/");
    } else if (sessionStorage.getItem("correo")) {
      this.correo = sessionStorage.getItem("correo");
      sessionStorage.removeItem("correo");
      if (sessionStorage.getItem("codigo")) {
        this.opt = sessionStorage.getItem("codigo");
        sessionStorage.removeItem("codigo");
      }
    } else {
      this.$router.push("/");
    }
  },
  watch: {},
  props: {},
  components: {
    formemail,
    formrut,
    formtext,
    formbtnsend,
    formbtncancel,
    checkTipoUsuario,
    formnumero,
    formmultiplecombobox,
  },
  methods: {
    volver: function () {
      this.$router.push("/");
    },
    submitNew: async function () {
      const { valid } = await this.$refs.formNew.validate();
      if (valid) {
        this.setFullLoading(true, "Validando código...");
        this.validating = true;
        let fd = new FormData();
        fd.append("codigoOPT", this.opt);
        fd.append("emailOPT", this.correo);
        var json = await enviarJsonPOST("usuario/validar_correo", fd);
        if (json) {
          this.validarMensaje(json);
          if (json.error == 0) {
            this.$router.push("/");
          }
        }
      }
      this.validating = false;
      this.setFullLoading(false, "Validando código...");
    },
    validarMensaje: function (obj) {
      this.$emit("validarMensaje", obj);
    },
    setFullLoading: async function (visible, texto) {
      this.$emit("setFullLoading", visible, texto);
    },
  },
};
</script>