<template flat>
    <v-btn
      class="bg-cyan-darken-1"
      dark
      block
      title
      type="submit"
      :loading="validating"
      >{{ label }}</v-btn
    >
</template>

<script>
export default {
  data: () => ({
    label: 'Enviar'
  }),
  props: {
    validating: Boolean,
    labelSet: String,
  },
  watch: {
    labelSet: function(v){
      this.label = v;
    }
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
  }
};
</script>

<style>
</style>