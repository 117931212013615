<template>
  <v-btn
    class="bg-cyan-lighten-5 text-cyan-darken-4"
    dark
    block
    title
    @click="cerrar(0)"
    :loading="validating"
    >{{ label }}</v-btn
  >
</template>

<script>
export default {
  data: () => ({
    label: "Cerrar",
  }),
  props: {
    validating: Boolean,
    labelSet: String
  },
  watch: {
    labelSet: function(val){
      this.laber = val;
    }
  },
  mounted: function(){
    if(this.labelSet){
      this.label = this.labelSet;
    }
  },
  methods: {
    cerrar: function (id) {
      this.$emit("cerrar", id);
      this.accion();
    },
    accion: function () {
      this.$emit("accion");
    },
  },
};
</script>

<style>
</style>